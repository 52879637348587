import React from 'react';
import PersonalWebsite from './PersonalWebsite';
import './index.css';

function App() {
  return (
    <PersonalWebsite />
  );
}

export default App;